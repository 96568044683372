import Growl from '@ux/growl';
import fetch from '@gasket/fetch';
import axios from 'axios';
import {
  addHours,
  differenceInHours,
  formatISO,
  fromUnixTime,
  parseISO,
} from 'date-fns';
import punycode from 'punycode/';
import psl from 'psl';

import { logger } from '@/common/initAPM';
import { isEmpty } from '@/common/helpers';
import { GROWL_FADE_TIME_FIREWALL } from '@/common/constants';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/common/hooks/use-local-storage';
import { pfidHasWAF } from '@/helpers/ApiHelper';
import { FirewallSetupStep, FirewallStatus } from '@/common/enums';
import { getBffCsrfToken } from '@/components/services/util';

/**
 * call to internal API, which calls Product API, to obtain WAF site data
 * @param {object} intl - 'react-intl' for messages
 * @param {string} siteId - identifier of the site
 * @param {function} setIsLoading - function to set a loading indicator
 * @param {function} onSuccess - callback function to perform after site data returned
 * @param onError
 * @param shouldGrowl
 * @returns {Promise<object>} - site data
 */
export const getWafSiteData = ({
  intl,
  siteId,
  setIsLoading,
  onSuccess,
  onError,
  shouldGrowl = true,
}) => {
  if (!siteId) return;

  const { addGrowlMessage } = Growl;

  if (typeof setIsLoading === 'function') {
    setIsLoading(true);
  }

  return fetch(`/api/firewall/sites/${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((resp) => resp.json())
    .then((json) => {
      // if there is relevant site data, trigger callback
      if (onSuccess && json?.domain) {
        onSuccess(json);
      }
      return json;
    })
    .catch((e) => {
      if (typeof onError === 'function') {
        onError(e);
      }

      logger.error(e);

      shouldGrowl &&
        addGrowlMessage({
          title: intl.formatMessage({ id: 'firewall__api_error_title' }),
          id: `init-firewall-error-message`,
          children: intl.formatMessage({
            id: `firewall__loading_firewall_error`,
          }),
          emphasis: 'critical',
          lifetime: GROWL_FADE_TIME_FIREWALL,
        });
    })
    .finally(() => {
      if (typeof setIsLoading === 'function') {
        setIsLoading(false);
      }
    });
};

/**
 * Activate a site on WAF
 * @param {object} data - config options for new waf site
 * @returns {Promise<object>} - new site id
 */
export const activateWafSite = async (data) => {
  return await axios.post(`/api/firewall/sites`, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

/**
 * Delete a site from WAF 2.0
 * @param {string} siteId - identifier of the site
 */
export const deleteWafSite = (siteId) => {
  return fetch(`/api/firewall/sites/${siteId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((resp) => resp)
    .catch((e) => {
      logger.error(e);
    });
};

export const checkDomainDNS = ({ domain, type }) => {
  return fetch(`/api/firewall/dns/${domain}?type=${type}`)
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((e) => {
      logger.error(e);
    });
};

export const checkDomainTLS = ({ domain }) => {
  return fetch(`/api/firewall/tls/${domain}`)
    .then((resp) => resp.json())
    .then((json) => {
      return { tls: +(json.status === 200) };
    })
    .catch((e) => {
      logger.error(e);
    });
};

export const getDNSRecords = ({ domain, type, name }) => {
  return fetch(`/api/firewall/dns/${domain}/fetch?type=${type}&name=${name}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((e) => {
      logger.error(e);
    });
};

export const getDomainConfig = async ({ domain }) => {
  try {
    const req = await axios(`/api/firewall/config/${domain}`);
    return req.data;
  } catch (error) {
    const msg = 'Failed to retrieve domain config data';
    logger.error({ ...error, reason: msg });

    return {
      error: true,
      reason: msg,
    };
  }
};

export const updateDNSRecords = ({
  siteId,
  domain,
  data,
  validate = false,
}) => {
  return fetch(
    `/api/firewall/dns/${domain}/update?siteId=${siteId}&validate=${validate}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ data }),
    },
  )
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((e) => {
      logger.error(e);
      return { error: true };
    });
};

export const deleteDNSRecords = ({ domain, type, name }) => {
  return fetch(`/api/firewall/dns/${domain}/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      name,
      type,
    }),
  });
};

export const checkDomainConnect = ({ domain }) => {
  const redirect = window.location.href;

  return fetch(`/api/firewall/domain_connect/${domain}/?redirect=${redirect}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((e) => {
      logger.error(e);
    });
};

/**
 * Parses verification records into a TagList for DNS API payload
 * @param {array} data  - Array of verification records
 * @param {string} domain  - The subject domain name
 * @returns {*[]} - Taglist array
 *
 * @see http://dns.api.int.test-godaddy.com/#!/____internal_v1_dns-int/addZoneProviderService/PatchTemplate
 */
export const parseVerificationTaglist = (data = [], domain) => {
  const domainASCII = punycode.toASCII(domain);
  const tagList = [];

  data.forEach((r, i) => {
    tagList.push({
      tag: `~~txt${i + 1}~~`,
      value: r.value,
    });
    tagList.push({
      tag: `~~host_txt${i + 1}~~`,
      value: r.name.replace('.' + domainASCII, ''),
    });
  });

  // NOTE this is a workaround for using the dns template "firewall-verify"
  // groupid a1 with only one txt record
  // by setting txt2 to something similar as txt1
  // It's not used but makes it look acceptable on the customers
  // dcc dns records page
  // It would only stick around for a few minutes
  // until CF passes the second validation record to create
  // (only on accounts where cert validation was historically done
  // will it persist long term)
  if (data.length === 1) {
    tagList.push({
      tag: '~~txt2~~',
      value: data[0].value,
    });
    tagList.push({
      tag: '~~host_txt2~~',
      value: `2${data[0].name.replace('.' + domainASCII, '')}`,
    });
  } else if (data.length === 3) {
    tagList.push({
      tag: '~~txt4~~',
      value: data[2].value,
    });
    tagList.push({
      tag: '~~host_txt4~~',
      value: `2${data[2].name.replace('.' + domainASCII, '')}`,
    });
  }

  return tagList;
};

/**
 * Gets the root domain
 * @param {string} domain - the domain || subdomain
 * @returns {string | null}
 **/
export const getRootDomain = (domain) => {
  if (!domain || typeof domain !== 'string') return null;

  try {
    const fullUrl = /^(https?)/.test(domain) ? domain : 'https://' + domain;
    const theUrl = new URL(fullUrl);
    const parsed = psl.parse(theUrl.hostname);
    return parsed.domain;
  } catch (e) {
    logger.error(e);
  }

  return null;
};

/**
 * Checks if host is a subdomain
 * @param {string} domain - the domain || subdomain
 * @returns {boolean}
 **/
export const isSubDomain = (domain) => {
  try {
    const fullUrl = /^(https?)/.test(domain) ? domain : 'https://' + domain;
    const theUrl = new URL(fullUrl);
    const hostname = theUrl.hostname.replace('www.', '');
    return hostname !== getRootDomain(hostname);
  } catch (e) {
    logger.error(e);
  }

  return false;
};

/**
 * Checks if a domain is using GoDaddy nameservers
 * @param {string} domain - the domain || subdomain
 * @returns {boolean}
 **/
export const hasInternalNS = async (domain) => {
  const rootDomain = getRootDomain(domain);

  if (!rootDomain) return false;

  try {
    const resp = await fetch(`/api/firewall/dns/${rootDomain}?type=NS`);

    if (!resp.ok) {
      logger.error(`An error occurred ${resp.status}`);
    }

    const json = await resp.json();

    return json?.data?.some((r) => r.includes('.domaincontrol.com'));
  } catch (e) {
    logger.error(e);
  }
};

export const getVerificationRecords = (siteData) => {
  let records = [];

  if (!isEmpty(siteData?.productData?.cloudFlare?.certificateValidation)) {
    records = [
      ...records,
      ...siteData.productData.cloudFlare.certificateValidation,
    ];
  }
  if (!isEmpty(siteData?.productData?.cloudFlare?.domainValidation)) {
    records = [...records, ...siteData.productData.cloudFlare.domainValidation];
  }

  return [...records];
};

/**
 * Checks if the WAF2.0 CAAs are allowed to issue certificates i.e.Let’s Encrypt & Google Trust Services
 * @param {array} data - the CAA DNS records data
 * @returns {boolean}
 *
 * @see - https://godaddy-corp.atlassian.net/browse/WS-12156
 * @see - https://developers.cloudflare.com/ssl/reference/certificate-authorities/
 */
export const wafCAAsAllowed = (data) => {
  const letsEncryptIsAllowed = data.find((r) => {
    return (
      r.critical === 0 &&
      (r.issue === 'letsencrypt.org' || r.issuewild === 'letsencrypt.org')
    );
  });

  const googleTrustServiceIsAllowed = data.find((r) => {
    return (
      r.critical === 0 &&
      (r.issue === 'pki.goog; cansignhttpexchanges=yes' ||
        r.issuewild === 'pki.goog; cansignhttpexchanges=yes')
    );
  });

  return !!letsEncryptIsAllowed && !!googleTrustServiceIsAllowed;
};

/**
 * Gets the site's setup data from localStorage.
 *
 * @param {string} siteId - The site ID
 * @param {int} validity - The data validity; data with ttl beyond the validity will be discarded.
 * @returns {object|undefined} - The valid site setup data.
 */
export const getStoredSetupStepData = ({ siteId, validity = 24 } = {}) => {
  if (!siteId) return null;

  const siteItem = `secui-waf-setup-${siteId}`;
  const localData = getLocalStorageItem(siteItem);

  if (!localData?.ttl) return null;

  const ttlDiff = differenceInHours(parseISO(localData.ttl), new Date());

  if (ttlDiff > validity) {
    removeLocalStorageItem(siteItem);
    return null;
  }

  return localData;
};

/**
 * Persists the site setup data into the localStorage
 *
 * @param {string} siteId - The site ID
 * @param {int} activationStep - The activation step number (1-7)
 * @param {string} revalidatedAt - The revalidation timestamp
 */
export const persistSiteSetupData = ({
  siteId,
  activationStep,
  revalidatedAt,
} = {}) => {
  if (!siteId || !activationStep) return;

  const ttl = formatISO(addHours(new Date(), 24));

  setLocalStorageItem(`secui-waf-setup-${siteId}`, {
    siteId,
    activationStep,
    revalidatedAt,
    ttl,
  });
};

/**
 * Checks if  plan is integrated via MWP or has been upgraded already
 * @param {object} data - The site data
 * @returns {boolean} - If the site has integrated firewall
 */
export const isWAFPlanUpgradable = (data) => {
  // Only sites on SUCCESS can be upgraded
  if (![FirewallStatus.SUCCESS, FirewallStatus.LIVE].includes(data?.status)) {
    return false;
  }

  return data?.planId === 'CDNWithWAF' && !data?.upgradePlanId;
};

/**
 * Checks if a valid subscription for the domain exists in the shopper's subscriptions
 * @param {object[]} subscriptions - The subscriptions for the shopper
 * @param {string} subscriptionId - The subscription ID to check for
 * @returns {boolean} - If a valid subscription exists
 */
export const hasValidSubscription = (subscriptions, subscriptionId) => {
  if (!subscriptions || !subscriptionId) return false;

  return subscriptions.some(
    (sub) => sub.externalId === subscriptionId && pfidHasWAF(sub.pfid),
  );
};

/**
 * Gets the setup data for a domain
 * @param {string} domain - the domain
 * @returns {Promise<object>} - The setup data
 */
export const getSetupData = async ({ domain }) => {
  try {
    const resp = await axios.get(`/api/firewall/setup/${domain}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!resp.data?.data) {
      logger.error('Failed to load Firewall 2.0 setup data.');
      return { error: true };
    }

    return resp.data?.data;
  } catch (e) {
    logger.error('Failed to load Firewall 2.0 setup data.', e);
    return { error: true };
  }
};

/**
 * Upgrades a site's WAF/CDN via the BFF
 * @param {string} siteId - The site ID
 * @param {string} BFFHost - The BFF host
 * @returns {Promise<object>} - The response data
 */
export const upgradeWAFCDN = async ({ siteId, BFFHost }) => {
  try {
    if (!siteId) {
      throw new Error('Invalid site ID.');
    }

    const csrfToken = await getBffCsrfToken();

    const resp = await axios.patch(
      `${BFFHost}/api/wss/firewall/actions/upgrade/${siteId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'x-xsrf-token': csrfToken,
        },
        withCredentials: true,
      },
    );

    return resp.data;
  } catch (e) {
    logger.error(`Firewall 2.0 upgrade failed - ${e.message}`);
    return { error: true };
  }
};

/**
 * Triggers a refresh of the site's activation data
 * @param {object} intl - 'react-intl' instance
 * @param {string} siteId - identifier of the site
 * @returns {Promise<void>} - void
 */
export const validateDNS = async ({ intl, siteId }) => {
  if (!siteId) return;

  const { addGrowlMessage } = Growl;

  try {
    // Track revalidation
    persistSiteSetupData({
      siteId,
      activationStep: FirewallSetupStep.VERIFY,
      revalidatedAt: formatISO(new Date()),
    });

    const resp = await axios.post(`/api/firewall/dns/${siteId}/validate`);

    if (resp.data) {
      addGrowlMessage({
        title: 'Please wait whilst the data is refreshed.',
        emphasis: 'success',
        lifetime: GROWL_FADE_TIME_FIREWALL,
      });
    }
  } catch (e) {
    logger.error(e);
    addGrowlMessage({
      title: intl.formatMessage({ id: 'firewall__api_error_title' }),
      emphasis: 'critical',
      lifetime: GROWL_FADE_TIME_FIREWALL,
    });
  }
};

/**
 * Checks if the site's WAF validation should be revalidated
 * @param {object} siteData - The site data
 * @returns {boolean} - If the site should be revalidated
 */
export const shouldRevalidate = ({ created, siteId }) => {
  if (!created) return false;

  const savedStepData = getStoredSetupStepData({ siteId });

  // If the site has not been revalidated, revalidate 1 hour after creation
  if (!savedStepData?.revalidatedAt) {
    return differenceInHours(new Date(), new Date(fromUnixTime(created))) >= 1;
  }

  // If the site has been revalidated, revalidate 1 hour after the last revalidation
  return (
    differenceInHours(
      new Date(),
      new Date(parseISO(savedStepData.revalidatedAt)),
    ) >= 1
  );
};

/**
 * Call to BFF to set the cache level for a site
 * @param {string} BFFHost - The BFF host
 * @param {string} siteId - The site ID to set cache for
 * @param {('CACHINGOPTIMIZED'|'CACHINGDISABLED'|'MINIMALCACHING'|'SITECACHE')} cacheLevel - The cache level to set
 * @returns {Promise<object>} - Response data
 */
export const setCacheLevel = async ({ BFFHost, siteId, cacheLevel }) => {
  try {
    if (!siteId) {
      throw new Error('Invalid site ID.');
    }

    if (!cacheLevel) {
      throw new Error('Invalid cache level.');
    }

    const csrfToken = await getBffCsrfToken();

    const resp = await axios.patch(
      `${BFFHost}/api/wss/firewall/actions/set-caching-level/${siteId}`,
      {
        cacheLevel,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'x-xsrf-token': csrfToken,
        },
        withCredentials: true,
      },
    );

    return resp.data;
  } catch (e) {
    logger.error(`Failed to set cache level for site ${siteId}`, e);
    throw new Error('Failed to set cache level.');
  }
};

/**
 * Map Plan ID to product name.
 * @param {string} planID Firewall Plan ID
 * @returns {string} Firewall Plan map
 */
export const firewallPlanMap = (planID) => {
  const plans = {
    WSSWAFBasic: 'Website Security Firewall',
    CDNWithWAF: 'Managed Wordpress',
    MWCSWAFCDNBasic: 'Managed Woo Commerce Stores',
    VPSWAFCDNBasic: 'Virtual Private Servers',
  };

  if (!plans[planID]) return 'Upgraded Firewall';

  return plans[planID];
};
