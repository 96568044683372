module.exports = {
  FIREWALL_ONE_IS_SUCCESS: 1,
  FIREWALL_ZERO_IS_SUCCESS: 0,
  FIREWALL_IS_ACTIVE: 1,
  DEFAULT_ICON_WIDTH: 24,
  DEFAULT_ICON_HEIGHT: 24,
  DEFAULT_ICON_COLOR: 'black',
  STATUS_ICON_WIDTH: 32,
  STATUS_ICON_HEIGHT: 32,
  SECUI_PRODUCT_NAME_COOKIE: 'secuiProductName',
  REQUEST_STATUS_FAILED: 0,
  REQUEST_STATUS_SUCCESS: 1,
  REQUEST_STATUS_LOADING: 2,
  GROWL_FADE_TIME_FIREWALL: 30000,
};
