import { useEffect, useRef, useState } from 'react';

export const setLocalStorageItem = (key, value) => {
  try {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
  } catch (e) {
    console.log('Error while persisting data to localstorage');
  }
};

export const getLocalStorageItem = (key) => {
  try {
    const value = window.localStorage.getItem(key);
    return JSON.parse(value);
  } catch (error) {
    console.log('Error while parsing localstorage data');
  }

  return null;
};

export const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

export const useLocalStorage = (key, initialValue) => {
  const keyRef = useRef(key);
  const initialValueRef = useRef(initialValue);
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    const value = getLocalStorageItem(keyRef.current);

    if (!value) {
      setStoredValue(initialValueRef.current);
      return;
    }

    try {
      const parsed = JSON.parse(value);
      setStoredValue(parsed);
    } catch (error) {
      console.log('Error while retrieving data from localstorage');
    }
  }, []);

  useEffect(() => {
    setLocalStorageItem(keyRef.current, storedValue);
  }, [storedValue]);

  return [storedValue, setStoredValue];
};
